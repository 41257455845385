import React from 'react'
import { message } from 'antd'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import KeyPersonHeader from './component/Header.js'

import './index.less'

const Loading = Loader.Loading
const NoData = Loader.loadBaseComponent('NoData')
const HorizontalScrollLayout = Loader.loadBaseComponent('HorizontalScrollLayout')
const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent')
const ImageMovieMap = Loader.loadBusinessComponent('ImageMovieMap')
const KeyPonitRollingCard = Loader.loadBusinessComponent('Card', 'KeyPonitRollingCard')
const PageDetails = Loader.loadBusinessComponent('PageDetails')

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider('tab', 'user', 'device')
@observer
class KeyPersonnelDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      data: {},
      oldData: undefined,
      handleVisible: false,
      operationDetail: '',
      alarmInfoList: [],
      detailList: [], // 外部告警列表
      detailpoint: [],
      isRealAlarm: undefined,
      libType: undefined
    }
    /*
		libType
		详情类型
			重点人员布控历史告警详情  1
			非法入侵告警详情         2
			魅影告警								3
			专网套件告警详情         4
	*/
  }

  componentWillMount() {
    let { history } = this.props
    const { id } = Utils.queryFormat(history.location.search)
    window.LM_DB.get('parameter', id)
      .then(data => {
        this.setState({
          loading: true,
          detailList: data.list,
          libType: data.libType,
          searchData: data.searchData,
          isRealAlarm: data.isRealAlarm
        })
        return this.getDetail(id).then(() => {
          this.getAlarmList()
        })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  getDetail = id => {
    const { libType, isRealAlarm = false } = this.state
    return Service.alarmResult.alarmResults({ id, libType, isRealAlarm }).then(res => {
      let data = res.data
      this.setState({
        loading: false,
        data,
        operationDetail: data.operationDetail
      })
      return data
    })
  }

  handleText = e => {
    const value = e.target.value
    if (value.length > 200) {
      message.info('最大长度不超过200')
      return
    }
    this.setState({
      operationDetail: value
    })
  }

  handleOpenModal = type => {
    this.setState({
      type,
      handleVisible: true
    })
  }

  onModalCancel = () => {
    this.setState({
      handleVisible: false
    })
  }
  //保存备注
  saveText = () => {
    let { data, operationDetail, libType, isRealAlarm } = this.state
    if (operationDetail === data.operationDetail) {
      return
    }
    Service.alarmResult
      .handleAlarmResult(
        {
          id: data.id,
          operationDetail
        },
        {
          libType,
          isRealAlarm
        }
      )
      .then(res => {
        message.success('保存备注成功')
        this.setState({
          data: res.data
        })
      })
      .catch(err => message.error(err.data.message))
  }

  // 处理有效无效
  handleOk = () => {
    let { data, type, detailList, libType, isRealAlarm, oldData } = this.state
    Service.alarmResult
      .handleAlarmResult(
        {
          id: data.id,
          isEffective: type
        },
        {
          libType,
          isRealAlarm
        }
      )
      .then(res => {
        this.setState({
          data: res.data,
          handleVisible: false
        })
        return res
      })
      .then(res => {
        message.info('设置告警状态成功')
        SocketEmitter.emit(SocketEmitter.eventName.resolveAlarm, res.data)
        let nextDetail = undefined
        if (detailList && detailList.length > 0) {
          let chose = {}
          if (oldData) {
            chose = detailList.find(v => v.id == oldData.id)
          } else {
            chose = detailList.find(v => v.id == data.id)
          }
          let number = detailList.indexOf(chose)
          if (number < detailList.length && number > -1) {
            nextDetail = detailList[number + 1]
          }
        }
        if (nextDetail) {
          this.changeDetailView(nextDetail.id)
        } else {
          this.getAlarmList()
        }
      })
  }
  //获取下方列表
  getAlarmList = (item = false) => {
    let data = item ? item : this.state.data
    let option = { monitorPersonPictureId: data.infoId }
    return Service.alarmResult.queryAlarmResults(option).then(res => {
      // if (res.data.list.length > 0) {
      //   res.data.list.map(v => {
      //     if(v.isHandle == 0 || v.isEffective !== 0) {
      //       v.checked = 1;
      //     } else {
      //       v.checked = 0;
      //     }
      //   });
      // }
      this.setState({
        alarmInfoList: res.data.list
      })
    })
  }

  // 更新地址栏url
  updatePage = id => {
    const { tab } = this.props
    const { searchData, isRealAlarm, libType } = this.state
    window.LM_DB.add('parameter', {
      id: id.toString(),
      libType,
      isRealAlarm,
      searchData
    }).then(() => {
      tab.goPage({
        moduleName: 'epidemicPersonTrackDetail',
        data: { id },
        action: 'replace',
        isUpdate: true
      })
    })
  }

  handleChangeList = id => {
    this.updatePage(id)
    this.getDetail(id).then(() => {
      this.getAlarmList()
    })
  }

  changeDetailView = id => {
    const { detailList } = this.state
    this.updatePage(id)
    this.getDetail(id).then(item => {
      this.getAlarmList(item).then(() => {
        let data = detailList.find(v => v.id === id)
        this.setState({
          oldData: data
        })
      })
    })
  }

  renderItem = (item, index) => {
    const { data } = this.state
    return <KeyPonitRollingCard data={item} isActive={item.id == data.id} libType={1} handleChangeList={this.handleChangeList} />
  }
  renderContent() {
    let { data = {}, alarmInfoList, libType, operationDetail, oldData, loading, detailList = [] } = this.state
    if (loading) {
      return null
    }
    if (Object.keys(data).length === 0) {
      return <NoData />
    }
    let dataIndex = detailList.findIndex(v => (oldData ? v.id === oldData.id : v.id === data.id)),
      preData = undefined,
      nextData = undefined
    if (dataIndex > 0) {
      preData = detailList[dataIndex - 1]
      nextData = detailList[dataIndex + 1]
    }
    if (dataIndex === 0 && detailList.length > 1) {
      nextData = detailList[dataIndex + 1]
    }
    return (
      <>
        <KeyPersonHeader
          data={data}
          saveText={this.saveText}
          handleText={this.handleText}
          handleOpenModal={this.handleOpenModal}
          operationDetail={operationDetail}
        />
        <div className="detail_imm">
          {preData ? (
            <PageDetails classNameNode="detail-text" pageType="pre" imgUrl={preData.faceUrl} id={preData.id} onChange={this.changeDetailView} />
          ) : (
            <div className="null" />
          )}
          <ImageMovieMap type="personAlarm" data={data} libType={libType} key={data.id} dataKey={'infoId'} getAid={this.getAid} />
          {nextData ? (
            <PageDetails classNameNode="detail-text" pageType="next" imgUrl={nextData.faceUrl} id={nextData.id} onChange={this.changeDetailView} />
          ) : (
            <div className="null" />
          )}
        </div>
        <HorizontalScrollLayout size={6} data={alarmInfoList} className="key-ponit-horizont" currentIndex={dataIndex} renderItem={this.renderItem} />
      </>
    )
  }
  render() {
    let { handleVisible, type, loading } = this.state
    return (
      <div className="key-perosnnel-detail">
        <Loading loading={loading}>{this.renderContent()}</Loading>
        <ConfirmComponent
          title={type == 1 ? '有效告警确认' : '无效告警确认'}
          visible={handleVisible}
          onCancel={this.onModalCancel}
          onOk={this.handleOk}
          width={320}
          icon={type == 1 ? 'icon-S_Photo_ThumbEffective' : 'icon-S_Photo_ThumbInvalid'}
          children={<div>点击“确定”将其标注为{type == 1 ? '有' : '无'}效告警？</div>}
        />
      </div>
    )
  }
}

export default KeyPersonnelDetail
