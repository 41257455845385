import React from "react";
import moment from "moment";
const AlarmDetailHeader = Loader.loadBusinessComponent(
  "MonitorHistoryAlarm",
  "AlarmDetailHeader"
);

class KeyPersonHeader extends React.Component {
  render() {
    let {
      data = {},
      handleText,
      operationDetail,
      handleOpenModal,
      saveText
    } = this.props;
    return (
      <AlarmDetailHeader
        score={data.score}
        saveText={saveText}
        handleText={handleText}
        operationDetail={operationDetail}
        handleOpenModal={handleOpenModal}
        data={data}
        handleAuthName='keyPersonnelHandle'
        rightCenterP={
          <>
            {" "}
            <p
              className="right-center-p"
              title={data.objectMainJson && data.objectMainJson.name}
            >
              <span className="right-center-span">姓名：</span>
              {(data.objectMainJson && data.objectMainJson.name) || "-"}
            </p>
            <p className="right-center-p" title={data.libName}>
              <span className="right-center-span"> 所在布控库：</span>
              {data.libName || "-"}
            </p>
            <p className="right-center-p">
              <span className="right-center-span">性别：</span>
              {(data.objectMainJson && data.objectMainJson.gender) || "-"}
            </p>
            <p className="right-center-p" title={data.taskName}>
              <span className="right-center-span">所在布控任务：</span>
              {data.taskName}
            </p>
            <p className="right-center-p">
              <span className="right-center-span">民族：</span>
              {(data.objectMainJson && data.objectMainJson.nationality) || "-"}
            </p>
            <p className="right-center-p" title={data.deviceName}>
              <span className="right-center-span">告警设备名称：</span>
              {data.deviceName || "-"}
            </p>
            <p className="right-center-p">
              <span className="right-center-span">身份证号：</span>
              {(data.objectMainJson && data.objectMainJson.identityCardNumber) ||
                "-"}
            </p>
            <p className="right-center-p" title={data.address}>
              <span className="right-center-span">告警设备地址：</span>
              {data.address || "-"}
            </p>
            <p className="right-center-p">
              <span className="right-center-span">出生年月：</span>
              {(data.objectMainJson && data.objectMainJson.birthday) || "-"}
            </p>
            <p className="right-center-p">
              <span className="right-center-span">告警时间：</span>
              {(data &&
                moment(+data.captureTime).format("YYYY.MM.DD HH:mm:ss")) ||
                "-"}
            </p>
          </>
        }
      />
    );
  }
}

export default KeyPersonHeader;
